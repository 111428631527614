/* - - - - - - - - - - - - - -       generate random string       - - - - - - - - - - - - - - - */
function generateRandomString(length) {
	const chars = 'abcdefghijklmnopqrstuvwxyz0123456789';
	let result = '';
	for (let i = 0; i < length; i++) {
		let randomIndex = '';
		if (i) {
			randomIndex = Math.floor(Math.random() * chars.length);
		} else {
			randomIndex = Math.floor(Math.random() * [...chars].filter(char => /[a-z]/i.test(char)).join('').length);
		}
		result += chars[randomIndex];
	}
	return result;
}

/* - - - - - - - - - - - - - -       add image       - - - - - - - - - - - - - - - */
function addImage($uploadFile, id, file, fileSrc) {
	const fileTpl = `
			<div id="file-${id}" class="form-upload__file">
				<div class="form-upload__file-img"><img src="${fileSrc}" alt="${file.name}"></div>
				<div class="form-upload__file-title">${file.name}</div>
				<div class="form-upload__file-remove js-remove-file" data-id="${id}"><span class="icon icon-i-close"></span></div>
			</div>
		`;
	const $uploadList = $uploadFile.find('.form-upload__list');
	$uploadList.append(fileTpl);
}

/* - - - - - - - - - - - - - -       toggle additional field       - - - - - - - - - - - - - - - */
function toggleAdditionalField() {
	const $additional = $('.form-additional');
	$additional.each(function() {
		const $additionalField = $(this).find('[type="checkbox"][data-additional], [type="radio"][data-additional]');
		const $additionalBox = $(this).find('div[data-additional]');
		if ($additionalField.length && $additionalField.prop('checked')) {
			$additionalBox.removeClass("hidden");
		} else {
			$additionalBox.addClass("hidden");
		}
	});
}

/* - - - - - - - - - - - - - -       init events grid       - - - - - - - - - - - - - - - */
function initEventsGrid() {
	// const fontSize = parseInt($('html').css('font-size'));
	// console.log(fontSize)
	const $eventsGrid = $('.js-events-grid').masonry({
		gutter: 0,
		itemSelector: '.events-box',
		stamp: '.events-stamp',
		horizontalOrder: true
	});
	$eventsGrid.imagesLoaded().progress( function() {
		$eventsGrid.masonry('layout');
	});
}

/* - - - - - - - - - - - - - -       progress filled submission       - - - - - - - - - - - - - - - */
function progressFilledSubmission() {
	$('.accordion-box').each(function() {
		const $accordionBox = $(this);
		const $accordionCounter = $accordionBox.find('.accordion-box__counter');
		const $accordionFieldList = $accordionBox.find('.form-group');
		const $accordionCheckboxList = $accordionBox.find('.form-checkbox');
		const $accordionUploadList = $accordionBox.find('.form-upload');
		const index = $accordionBox.index();
		const tab = $accordionBox.closest('.tabs-box').data('tab');
		const countField = $accordionFieldList.length + $accordionCheckboxList.length + $accordionUploadList.length;
		const $submissionSidebar = $('.submission-sidebar__step');
		const $submissionStep = $submissionSidebar.find(`.submission-sidebar__step-group[data-tab="${tab}"] .submission-sidebar__step-box`).eq(index);
		let countFilledField = 0;
		let progress = 0;
		$accordionFieldList.each((i, item) => {
			if ($(item).find('input:not(.iti__search-input), textarea, select').val() !== '') {
				countFilledField += 1;
			}
		});
		$accordionCheckboxList.each((i, item) => {
			let isChecked = false;
			if ($(item).find('input').is(':checked')) {
				isChecked = true;
			}
			if (isChecked) {
				countFilledField += 1;
			}
		});
		$accordionUploadList.each((i, item) => {
			if ($(item).find('input').val() !== '') {
				countFilledField += 1;
			}
		});
		progress = countFilledField / countField * 100;
		$accordionCounter.text(`${countFilledField}/${countField}`);
		$submissionStep.find('.submission-sidebar__step-progress-bar').css('width', `${progress}%`);
		$submissionStep.find('.submission-sidebar__step-progress-count').text(`${countFilledField}/${countField}`);
	});
}

function showCookie() {
	const cookieDelay = 5000;
	setTimeout(() => {
		$('.cookie').addClass('show');
	}, cookieDelay);
}

$(document).ready(function () {
	toggleAdditionalField();

	if ($('.cookie').length) {
		showCookie();
	}
	$('.js-close-cookie').on('click', function() {
		$('.cookie').removeClass('show');
	});

	/* - - - - - - - - - - - - - -       init inputmask       - - - - - - - - - - - - - - - */
	// $( '[type="tel"]' ).inputmask( '+38 (999) 999 99 99' );

	/* - - - - - - - - - - - - - -       init slick       - - - - - - - - - - - - - - - */
	// $( '.js-slider' ).slick({
	// 	infinite: false,
	// 	arrows: true,
	// 	dots: false,
	// 	slidesToScroll: 1,
	// 	slidesToShow: 2
	// });

	/* - - - - - - - - - - - - - -       input validate       - - - - - - - - - - - - - - - */
	function setInput() {
		$('input:not(.btn):not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="reset"]), textarea, select').each(function () {
			const $this = $(this);
			const $parent = $this.closest('.form-group');
			const isPlaceholder = $this.attr("placeholder") ? true : false;
			const isDataPlaceholder = $this.attr("data-placeholder") ? true : false;
			const isAutofill = $this[0].matches(':autofill') === true ? true : false;
			if ($this.val() !== '' || isPlaceholder || isDataPlaceholder || isAutofill) {
				$parent.addClass('filled');
			}
		});
	}
	// setInput(setInput, 10);
	setTimeout(setInput, 600);
	$(document).on('focus', 'input:not(.btn):not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="reset"]), textarea', function () {
		const $parent = $(this).closest('.form-group');
		$parent.addClass('filled');
	});
	$(document).on('blur', 'input:not(.btn):not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="reset"]), textarea', function () {
		$('input:not(.btn):not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="reset"]), textarea').each(function () {
			const $this = $(this);
			const $parent = $this.closest('.form-group');
			const isPlaceholder = $this.attr("placeholder") ? true : false;
			if ($this.val() === '' && !$this.is(':focus') && !isPlaceholder) {
				$parent.removeClass('filled');
			}
			if ($parent.length) {
				const str = $this.val();
				const regUrl = /^https?:\/\//;
				if (str.match(regUrl)) {
					$this.val(str.replace(regUrl, ''));
				}
			}
		});
	});
	$(document).on('input', '[type="checkbox"], [type="radio"]', function () {
		toggleAdditionalField();
	});
	// $('textarea').on('input', function () {
	// 	const $parent = $(this).closest('.form-group');
	// 	if ($(this).val() !== '') {
	// 		$parent.addClass('filled');
	// 	} else {
	// 		$parent.removeClass('filled');
	// 	}
	// });

	/* - - - - - - - - - - - - - -       counter textarea       - - - - - - - - - - - - - - - */
	function counterTextarea($textarea, value, maxCount) {
		const $parent = $textarea.closest('.form-group');
		const $counter = $parent.find('.textarea-counter');
		const count = $textarea.val().length;
		$counter.text(`${count}/${maxCount}`);
		if (count > maxCount) {
			$parent.addClass('has-error');
		} else {
			$parent.removeClass('has-error');
		}
	}
	function initTextarea() {
		$('.js-textarea').each(function() {
			const $textarea = $(this);
			const $parent = $textarea.closest('.form-group');
			const defaultCount = 500;
			const maxCount = $textarea.data('count') && $textarea.data('count') !== '' ? parseInt($textarea.data('count')) : defaultCount;
			let value = $textarea.val();
			const counterTpl = `
				<span class="textarea-counter"></span>
			`;
			$parent.prepend(counterTpl);
			counterTextarea($textarea, value, maxCount);

			$textarea.on('input', function() {
				// const newValue = $textarea.val();
				// if (value.length < newValue.length && value.length >= maxCount) {
				// 	$textarea.val(value);
				// } else {
				// 	value = newValue;
				// }
				// if (newValue.length > maxCount) {
				// 	$textarea.val(newValue.slice(0, maxCount));
				// }
				counterTextarea($textarea, value, maxCount);
			});
		});
	}
	initTextarea();

	/* - - - - - - - - - - - - - -       toggle field password       - - - - - - - - - - - - - - - */
	$(document).on('click', '.js-toggle-view', function() {
		const $this = $(this);
		const $parent = $this.closest('.form-group');
		if (!$this.hasClass('active')) {
			$this.addClass('active');
			$parent.find('input').attr('type', 'text');
		} else {
			$this.removeClass('active');
			$parent.find('input').attr('type', 'password');
		}
	});

	/* - - - - - - - - - - - - - -       clear field       - - - - - - - - - - - - - - - */
	$('.js-clear-field').on('click', function() {
		const $this = $(this);
		const $parent = $this.closest('.form-group');
		const $field = $parent.find('input');
		$field.val('');
		if (!$field.attr('placeholder')) {
			$parent.removeClass('filled');
		}
	});

	/* - - - - - - - - - - - - - -       init select2       - - - - - - - - - - - - - - - */
	function initSelect() {
		$(".js-select").select2({
			language: "en",
			width: "100%",
			dropdownPosition: "below"
			// minimumResultsForSearch: -1
		})
			.on("select2:open", function (e) {
				const $parent = e.target.closest(".form-group");
				$($parent).addClass("filled");
			})
			.on("select2:close", function (e) {
				const $parent = e.target.closest(".form-group");
				const value = e.target.value;
				const isPlaceholder = $(e.target).attr('data-placeholder') ? true : false;
				if (value === "" && !isPlaceholder) {
					$($parent).removeClass("filled");
				}
			})
			.on("select2:select", function (e) {
				const data = e.params.data;
				// console.log(data);
			});
	}
	// initSelect();
	setTimeout(initSelect, 10);

	/* - - - - - - - - - - - - - -       input AirDatepicker       - - - - - - - - - - - - - - - */
	const localeEn = {
		days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
		daysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
		daysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
		months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
		monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
		today: 'Today',
		clear: 'Clear',
		dateFormat: 'MM/dd/yyyy',
		timeFormat: 'hh:mm aa',
		firstDay: 0
	};
	const localeUk = {
		days: ['Неділя', 'Понеділок', 'Вівторок', 'Середа', 'Четвер', 'П’ятниця', 'Субота'],
		daysShort: ['Нед', 'Пнд', 'Вів', 'Срд', 'Чтв', 'Птн', 'Сбт'],
		daysMin: ['Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
		months: ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'],
		monthsShort: ['Січ', 'Лют', 'Бер', 'Кві', 'Тра', 'Чер', 'Лип', 'Сер', 'Вер', 'Жов', 'Лис', 'Гру'],
		today: 'Сьогодні',
		clear: 'Очистити',
		dateFormat: 'dd.MM.yyyy',
		timeFormat: 'HH:mm',
		firstDay: 1
	};
	const lang = $('html').attr('lang').slice(0, 2).toLowerCase();
	let dateLocale;
	if (lang === 'en') {
		dateLocale = localeEn;
	}
	if (lang === 'uk') {
		dateLocale = localeUk;
	}
	// let currentDate = new Date();
	// let minDate = new Date();
	// minDate.setFullYear(currentDate.getFullYear() - 35);
	// let maxDate = new Date();
	// maxDate.setFullYear(currentDate.getFullYear() - 18);
	function initDatepicker() {
		$('.js-date').each((i, datepicker) => {
			const $parent = $(datepicker).closest('.form-group');
			new AirDatepicker(datepicker, {
				locale: dateLocale,
				// minDate: minDate,
				// maxDate: maxDate,
				autoClose: true,
				dateFormat: 'dd/MM/yyyy',
				prevHtml: '<span class="icon icon-i-arr-small-left"></span>',
				nextHtml: '<span class="icon icon-i-arr-small-right"></span>',
				navTitles: {
					days: '<span>MMMM</span><span>yyyy</span><span class="icon icon-i-arr-small-down"></span>',
				},
				onSelect({date, formattedDate, datepicker}) {
					if ( date !== undefined ) {
						$(datepicker.$el).closest('.form-group').addClass('filled');
					} else {
						$(datepicker.$el).closest('.form-group').removeClass('filled');
					}
					progressFilledSubmission();
				},
				onShow(isFinished) {
					$parent.addClass('focused');
				},
				onHide(isFinished) {
					$parent.removeClass('focused');
				}
			});
		});
	}
	if ($('.js-date').length) {
		initDatepicker();
	}

	let currentPlanDate = new Date();
	// let minDate = new Date();
	// minDate.setFullYear(currentDate.getFullYear() - 35);
	// let maxDate = new Date();
	// maxDate.setFullYear(currentDate.getFullYear() - 18);
	function initPlanDatepicker() {
		let dateFormat = '';
		if (window.matchMedia('(max-width: 767px)').matches) {
			dateFormat = 'dd MMM yy';
		} else {
			dateFormat = 'dd MMMM yy';
		}
		$('.js-plan-date').each((i, datepicker) => {
			const $parent = $(datepicker).closest('.plan-tabs__box-date');
			new AirDatepicker(datepicker, {
				locale: dateLocale,
				minDate: currentPlanDate,
				// maxDate: maxDate,
				autoClose: true,
				dateFormat: dateFormat,
				prevHtml: '<span class="icon icon-i-arr-small-left"></span>',
				nextHtml: '<span class="icon icon-i-arr-small-right"></span>',
				navTitles: {
					days: '<span>MMMM</span><span>yyyy</span><span class="icon icon-i-arr-small-down"></span>',
				},
				onSelect({date, formattedDate, datepicker}) {
					// if ( date !== undefined ) {
					// 	$( datepicker.$el ).closest( '.form-group' ).addClass( 'filled' );
					// } else {
					// 	$( datepicker.$el ).closest( '.form-group' ).removeClass( 'filled' );
					// }
				},
				onShow(isFinished) {
					$parent.addClass('focused');
				},
				onHide(isFinished) {
					$parent.removeClass('focused');
				}
			});
		});
	}
	if ($('.js-plan-date').length) {
		initPlanDatepicker();
	}

	function initPlanRangeDatepicker() {
		const planDateStart = $('.js-plan-date-start');
		const $parentDateStert = planDateStart.closest('.plan-tabs__box-date');
		const startDatePicker = new AirDatepicker(planDateStart[0], {
			locale: dateLocale,
			minDate: currentPlanDate,
			autoClose: true,
			dateFormat: 'dd MMM yy',
			prevHtml: '<span class="icon icon-i-arr-small-left"></span>',
			nextHtml: '<span class="icon icon-i-arr-small-right"></span>',
			navTitles: {
				days: '<span>MMMM</span><span>yyyy</span><span class="icon icon-i-arr-small-down"></span>',
			},
			onSelect({date, formattedDate, datepicker}) {
				endDatePicker.update({
					minDate: formattedDate // Кінець не може бути раніше початку
				});
			},
			onShow(isFinished) {
				$parentDateStert.addClass('focused');
			},
			onHide(isFinished) {
				$parentDateStert.removeClass('focused');
			}
		});
		const planDateEnd = $('.js-plan-date-end');
		const $parentDateEnd = planDateStart.closest('.plan-tabs__box-date');
		const endDatePicker = new AirDatepicker(planDateEnd[0], {
			locale: dateLocale,
			minDate: currentPlanDate,
			autoClose: true,
			dateFormat: 'dd MMM yy',
			prevHtml: '<span class="icon icon-i-arr-small-left"></span>',
			nextHtml: '<span class="icon icon-i-arr-small-right"></span>',
			navTitles: {
				days: '<span>MMMM</span><span>yyyy</span><span class="icon icon-i-arr-small-down"></span>',
			},
			onSelect({date, formattedDate, datepicker}) {
				startDatePicker.update({
					maxDate: formattedDate // Кінець не може бути раніше початку
				});
				// if ( date !== undefined ) {
				// 	$( datepicker.$el ).closest( '.form-group' ).addClass( 'filled' );
				// } else {
				// 	$( datepicker.$el ).closest( '.form-group' ).removeClass( 'filled' );
				// }
			},
			onShow(isFinished) {
				$parentDateEnd.addClass('focused');
			},
			onHide(isFinished) {
				$parentDateEnd.removeClass('focused');
			}
		});
	}
	if ($('.js-plan-date-start').length && $('.js-plan-date-end').length) {
		initPlanRangeDatepicker();
	}

	/* - - - - - - - - - - - - - -       init phone       - - - - - - - - - - - - - - - */
	function initPhone() {
		const phoneList = document.querySelectorAll('[type="tel"]');
		phoneList.forEach(phone => {
			if (!$(phone).closest('.iti').length) {
				intlTelInput(phone, {
					separateDialCode: true,
					// countrySearch: false,
					useFullscreenPopup: false,
					strictMode: true,
				});
				phone.addEventListener("countrychange", (e) => {
					const $parent = $(e.target).closest('.form-group');
					const hiddenField = $parent.find('[type="hidden"]');
					const code = $parent.find('.iti__selected-dial-code').text();
					hiddenField.val(code);
				});
			}
		});
	}
	// initPhone();
	setTimeout(initPhone, 10);

	/* - - - - - - - - - - - - - -       init fancybox       - - - - - - - - - - - - - - - */
	Fancybox.bind( '[data-fancybox]', {
		autoFocus: false,
		placeFocusBack: false,
		dragToClose: false,
		closeExisting: true,
		tpl: {
			closeButton: '<button data-fancybox-close class="f-button is-close-btn" title="{{CLOSE}}">' +
					'<span class="icon icon-i-close"></span>' +
				'</button>'
		},
		on: {
			init: () => {
				$( '.header-overflow' ).removeClass( 'show' );
				$( 'body' ).removeClass( 'no-scroll' );
				$( '.header-modal' ).removeClass( 'show' );
			},
			close: ( fancybox ) => {

			},
		}
	});

	/* - - - - - - - - - - - - - -       rebuild header       - - - - - - - - - - - - - - - */
	function rebuildHeader() {
		const $headerMenu = $('.header-menu').detach();
		const $headerRight = $('.header-right').detach();
		const $headerLabel = $('.header-label').detach();
		const $headerTop = $('.header-top');
		const $headerBottom = $('.header-bottom');
		const $headerLeft = $('.header-left');
		const $action = $('.action');
		const $headerModal = $('.header-modal');
		if (window.matchMedia('(max-width: 1199px)').matches) {
			if ($action.length) {
				$action.append($headerLabel);
			}
		} else {
			$headerLeft.append($headerLabel);
		}
		if (window.matchMedia('(max-width: 767px)').matches) {
			$headerModal.append($headerRight);
			$headerModal.append($headerMenu);
		} else {
			$headerTop.append($headerRight);
			$headerBottom.append($headerMenu);
		}
	}
	rebuildHeader();
	$(window).on('resize', rebuildHeader);

	/* - - - - - - - - - - - - - -       show header header       - - - - - - - - - - - - - - - */
	function showHeaderModal() {
		$('body').addClass('no-scroll');
		$('.header-overflow').addClass('show');
		$('.header-modal').addClass('show');
	}

	/* - - - - - - - - - - - - - -       hide header header       - - - - - - - - - - - - - - - */
	function hideHeaderModal() {
		$('body').removeClass('no-scroll');
		$('.header-overflow').removeClass('show');
		$('.header-modal').removeClass('show');
	}

	$('.js-header-open').on('click', showHeaderModal);
	$('.js-header-close').on('click', hideHeaderModal);
	$('.header-overflow').on('click', hideHeaderModal);

	$(window).on('resize', function () {
		if (window.matchMedia('(min-width: 768px)').matches) {
			hideHeaderModal();
		}
	});

	/* - - - - - - - - - - - - - -       init events grid       - - - - - - - - - - - - - - - */
	// function initTileGrid() {
	// 	const $tileGrid = $('.js-tile-grid').masonry({
	// 		gutter: 0,
	// 		itemSelector: '.tile-box',
	// 		// horizontalOrder: true
	// 	});
	// 	$tileGrid.imagesLoaded().progress( function() {
	// 		$tileGrid.masonry('layout');
	// 	});
	// }
	// initTileGrid();

	/* - - - - - - - - - - - - - -       progress filled submission       - - - - - - - - - - - - - - - */
	if ( $('.accordion-box').length ) {
		// progressFilledSubmission();
		setTimeout(progressFilledSubmission, 10);
		$(document).on('input', 'input, textarea, select', function () {
			progressFilledSubmission();
		});
	}

	/* - - - - - - - - - - - - - -       toggle form tabs       - - - - - - - - - - - - - - - */
	$('.js-form-tabs [data-tab]').on('click', function() {
		const $this = $(this);
		const $parent = $this.closest(".form-tabs").parent();
		if (!$this.hasClass('active')) {
			$parent.find(".form-tabs [data-tab]").removeClass('active');
			$this.addClass('active');
			$parent.find('.form-content [data-tab]').removeClass('show');
			$parent.find(`.form-content [data-tab="${$this.attr('data-tab')}"]`).addClass('show');
		}
	});

	/* - - - - - - - - - - - - - -       toggle tabs       - - - - - - - - - - - - - - - */
	$('.js-tabs [data-tab]').on('click', function() {
		const $this = $(this);
		const $tabsList = $this.closest('.tabs-list');
		const $tabs = $this.closest('.tabs');
		if (!$this.hasClass('active')) {
			$tabsList.find('[data-tab]').removeClass('active');
			$this.addClass('active');
			$tabs.find('.tabs-content [data-tab]').removeClass('show');
			$tabs.find(`.tabs-content [data-tab="${$this.attr('data-tab')}"]`).addClass('show');
			$('.submission-sidebar__step-group').removeClass('show');
			$(`.submission-sidebar__step-group[data-tab="${$this.attr('data-tab')}"]`).addClass('show');
			progressFilledSubmission();
			initEventsGrid();
		}
	});

	function sliderTabs() {
		$('.js-slider-tabs').each(function() {
			const $tabs = $(this);
			const $tabsScroll = $tabs.find('.tabs-list__scroll');
			const $buttonPrev = $tabs.find('.tabs-prev');
			const $buttonNext = $tabs.find('.tabs-next');
			const maxWidth = $tabs.innerWidth();
			const scrollPadding = parseInt($tabsScroll.css('padding-left'));
			let scrollWidth = 0;
			let scrollPos = 0;
			$tabsScroll.find('li').each(function() {
				const $this = $(this);
				scrollWidth += $this.innerWidth();
			});
			scrollWidth = Math.ceil(scrollWidth);
			$buttonPrev.removeClass('show');
			$buttonNext.removeClass('show');
			if (maxWidth < scrollWidth) {
				$buttonNext.addClass('show');
			}
			$tabsScroll.on('scroll', function() {
				scrollPos = $tabsScroll.scrollLeft();
				if (scrollPos === 0) {
					$buttonPrev.removeClass('show');
				} else if (scrollPos > scrollWidth - maxWidth + scrollPadding - 1) {
					$buttonNext.removeClass('show');
				} else {
					$buttonPrev.addClass('show');
					$buttonNext.addClass('show');
				}
			});
			$buttonPrev.click(function() {
				scrollPos -= 50;
				$tabsScroll.animate({scrollLeft: scrollPos}, 250);
			});
			$buttonNext.click(function() {
				scrollPos += 50;
				$tabsScroll.animate({scrollLeft: scrollPos}, 250);
			});
		});
	}
	if ($('.js-slider-tabs').length) {
		sliderTabs();
		$(window).on('resize', sliderTabs);
	}

	/* - - - - - - - - - - - - - -       toggle accordion       - - - - - - - - - - - - - - - */
	if ($('.accordion-box').length) {
		$('.accordion-box').each(function() {
			const $this = $(this);
			if ($this.hasClass('show')) {
				$this.find('.accordion-box__body').slideDown(250);
			}
		});
	}
	$('.js-accordion').on('click', function() {
		const $this = $(this);
		const $parent = $this.closest('.accordion-box');
		if (!$parent.hasClass('show')) {
			$parent.addClass('show');
			$parent.find('.accordion-box__body').slideDown(250);
		} else {
			$parent.removeClass('show');
			$parent.find('.accordion-box__body').slideUp(250);
		}
	});

	/* - - - - - - - - - - - - - -       add image       - - - - - - - - - - - - - - - */
	// function addImage($uploadFile, id, file, fileSrc) {
	// 	const fileTpl = `
	// 		<div id="file-${id}" class="form-upload__file">
	// 			<div class="form-upload__file-img"><img src="${fileSrc}" alt="${file.name}"></div>
	// 			<div class="form-upload__file-title">${file.name}</div>
	// 			<div class="form-upload__file-remove js-remove-file" data-id="${id}"><span class="icon icon-i-close"></span></div>
	// 		</div>
	// 	`;
	// 	const $uploadList = $uploadFile.find('.form-upload__list');
	// 	$uploadList.append(fileTpl);
	// }

	/* - - - - - - - - - - - - - -       upload file       - - - - - - - - - - - - - - - */
	// let fileSrc;
	// function initUploadFile() {
	// 	$('.js-upload-file').each(function() {
	// 		const $upload = $(this);
	// 		const defaultSize = 3000000;
	// 		const defaultType = ['jpg', 'jpeg', 'png', 'gif', 'svg'];
	// 		const uploadSize = $upload.data('size') && $upload.data('size') !== '' ? parseInt($upload.data('size')) : defaultSize;
	// 		const uploadType = $upload.data('type') && $upload.data('type') !== '' ? $upload.data('type').split(',').map(type => type.trim()) : defaultType;
	// 		$upload.dmUploader({
	// 			url: '/img',
	// 			maxFiles: 3,
	// 			maxFileSize: uploadSize,
	// 			allowedTypes: 'image/*',
	// 			extFilter: uploadType,
	// 			onNewFile: function(id, file) {
	// 				console.log('Новий файл');
	// 				const $uploadFile = $(this).closest('.form-upload');
	// 				if (typeof FileReader !== 'undefined') {
	// 					const reader = new FileReader();
	//
	// 					reader.onload = function (e) {
	// 						fileSrc = e.target.result;
	// 						addImage($uploadFile, id, file, fileSrc);
	// 					};
	//
	// 					/* ToDo: do something with the img! */
	// 					reader.readAsDataURL(file);
	// 				}
	// 			},
	// 			onDragEnter: function() {
	// 				// Дії при заході файлів в область завантаження
	// 				console.log('Файл зайшов в область');
	// 				$(this).addClass('active');
	// 			},
	// 			onDragLeave: function() {
	// 				// Дії при виході файлів з області завантаження
	// 				console.log('Файл залишив область');
	// 				$(this).removeClass('active');
	// 			},
	// 			onInit: function() {
	// 				// Викликається при ініціалізації плагіна
	// 				console.log('dmUploader ініціалізовано');
	// 			},
	// 			onBeforeUpload: function(id) {
	// 				console.log('Файл ' + id + ' готується до завантаження');
	// 			},
	// 			onComplete: function() {
	// 				// Викликається коли завантаження завершено
	// 				console.log('Завершено завантаження');
	// 			},
	// 			onUploadProgress: function(id, percent) {
	// 				// Прогрес завантаження для конкретного файлу
	// 				console.log(`Завантаження файлу ${id}: ${percent}%`);
	// 				$(`#file-${id}`).find('.form-upload__file-img').html('<span class="icon icon-i-loading"></span>');
	// 			},
	// 			onUploadSuccess: function(id, data) {
	// 				// Викликається при успішному завантаженні файлу
	// 				console.log(`Файл ${id} успішно завантажено`);
	// 				// console.log($(this));
	// 				const file = data.files[0];
	// 				// const fileUrl = `/img/${fileName}`;
	// 				$(`#file-${id}`).find('.form-upload__file-img').html(`<img src="${fileSrc}" alt="${file.name}">`);
	// 			},
	// 			onUploadError: function(id, xhr, status, message) {
	// 				// Викликається, якщо сталася помилка під час завантаження
	// 				console.log(`Помилка завантаження файлу ${id}: ${message}`);
	// 				$(`#file-${id}`).addClass('error');
	// 				$(`#file-${id}`).find('.form-upload__file-img').html('<span class="icon icon-i-photo"></span>');
	// 			},
	// 			onFileTypeError: function(file) {
	// 				alert('Тип файлу не дозволений: ' + file.name);
	// 			},
	// 			onFileSizeError: function(file) {
	// 				alert('Файл занадто великий: ' + file.name);
	// 			},
	// 			onFallbackMode: function() {
	// 				alert('Ваш браузер не підтримує функціонал drag-and-drop');
	// 			}
	// 		});
	// 	});
	// }
	// if ( $('.js-upload-file').length ) {
	// 	initUploadFile();
	// }

	/* - - - - - - - - - - - - - -       remove file       - - - - - - - - - - - - - - - */
	// $(document).on('click', '.js-remove-file', function() {
	// 	let fileId = $(this).data('id');
	// 	$(`#file-${fileId}`).remove();
	// 	console.log('Файл з ID ' + fileId + ' видалено');
	// });

	/* - - - - - - - - - - - - - -       set number submission       - - - - - - - - - - - - - - - */
	function setNumberGroup($parent) {
		const $submission = $parent.parent();
		$submission.find('.submission-box').each((i, item) => {
			$(item).find('.submission-box__title b').text(i + 1);
			$(item).find('input').each((inFi, inputField) => {
				// console.log($(inputField))
				const newName = $(inputField).attr('name') && $(inputField).attr('name').replace(/\[\d+\]/, `[${i + 1}]`);
				$(inputField).attr('name', newName);
			});
		});
	}

	/* - - - - - - - - - - - - - -       set checkbox id       - - - - - - - - - - - - - - - */
	function setId($parent) {
		$parent.find('[type="checkbox"], [type="radio"]').each(function() {
			const $this = $(this);
			const $label = $this.siblings('label');
			const name = $this.attr('name');
			const regExp = /\[\d*\](\[\d*\])*/g;
			const result = name.match(regExp);
			let newName = '';
			if (result) {
				newName = name.replace(regExp, `-${generateRandomString(11)}`);
			} else {
				newName = `${name}-${generateRandomString(11)}`;
			}
			$this.attr('id', newName);
			$this.prop('checked', false);
			$label.attr('for', newName);
		});
	}

	function getClone($this) {
		const $parent = $this.closest('.form-add');
		const $info = $parent.find('.form-add__info');
		const minCount = $this.data('min-count') ? $this.data('min-count') : false;
		const maxCount = $this.data('max-count') ? $this.data('max-count') : false;
		const count = $parent.parent().find('> [data-duplicate]').length + 1;
		if (minCount) {
			if (minCount <= count) {
				$info.addClass('hidden');
			} else {
				$info.find('> *').removeClass('show');
				$info.find('> *').eq(count - 1).addClass('show');
				$info.removeClass('hidden');
			}
		} else {
			$info.find('> *').removeClass('show');
			$info.find('> *').eq(0).addClass('show');
		}
		if (maxCount && maxCount <= count) {
			$parent.addClass('hidden');
		} else {
			$parent.removeClass('hidden');
		}
	}

	function disableClone() {
		$('.js-clone-field').each(function() {
			const $this = $(this);
			getClone($this);
		});
	}
	if($('.js-clone-field').length) {
		disableClone();
	}

	/* - - - - - - - - - - - - - -       clone box       - - - - - - - - - - - - - - - */
	// $(document).on('click', '.js-clone-video-field', function(e) {
	// 	e.preventDefault();
	// 	const $this = $(this);
	// 	const $parent = $this.closest('.form-add');
	// 	const cloneVideoTpl = $parent.parent().find(`[data-clone="${$this.data('clone')}"]`).not($this).clone();
	// 	cloneVideoTpl.removeAttr('data-clone');
	// 	cloneVideoTpl.attr('data-duplicate', '');
	// 	cloneVideoTpl.find('.filled').removeClass('filled');
	// 	cloneVideoTpl.find('input').val('');
	// 	$parent.before(cloneVideoTpl);
	// 	setInput();
	// 	progressFilledSubmission();
	// 	getClone($this, $parent);
	// });

	/* - - - - - - - - - - - - - -       clone box       - - - - - - - - - - - - - - - */
	$(document).on('click', '.js-clone-field', function(e) {
		e.preventDefault();
		const $this = $(this);
		const $parent = $this.closest('.form-add');
		const cloneTpl = $parent.parent().find(`[data-clone="${$this.data('clone')}"]`).not($this).clone();
		cloneTpl.attr('data-duplicate', '');
		// if (~cloneTpl.attr('data-clone').indexOf('video-')) {
		// 	cloneTpl.attr('data-duplicate', '');
		// } else {
		// 	cloneTpl.find('[data-duplicate]').remove();
		// }
		cloneTpl.find('[data-duplicate]').remove();
		cloneTpl.removeAttr('data-clone');
		cloneTpl.find('.filled').removeClass('filled');
		cloneTpl.find('input:not([type="checkbox"]):not([type="radio"]), textarea, select').val('');
		setId(cloneTpl);
		cloneTpl.find('.form-upload__list').html('');
		cloneTpl.find('.select2').remove();
		if (cloneTpl.find('.iti').length) {
			const $field = cloneTpl.find('.iti').closest('.form-field');
			const input = $field.find('[type="tel"]').clone();
			input.removeAttr('placeholder');
			cloneTpl.find('.iti').remove();
			$field.append(input);
		}
		$parent.before(cloneTpl);
		setInput();
		initTextarea();
		initSelect();
		toggleAdditionalField();
		if (cloneTpl.find('.js-upload-file').length) {
			cloneTpl.find('.js-upload-file').each(function() {
				initUploadFile($(this));
			});
		}
		initDatepicker();
		initPhone();
		setNumberGroup(cloneTpl);
		progressFilledSubmission();
		disableClone();
	});

	/* - - - - - - - - - - - - - -       toggle plan tabs       - - - - - - - - - - - - - - - */
	$('.js-plan-tabs [data-tab]').on('click', function() {
		const $this = $(this);
		const $parent = $this.closest('.plan-tabs');
		if (!$this.hasClass('active')) {
			$parent.find(".plan-tabs__list [data-tab]").removeClass('active');
			$this.addClass('active');
			$parent.find('.plan-tabs__content [data-tab]').removeClass('show');
			$parent.find(`.plan-tabs__content [data-tab="${$this.attr('data-tab')}"]`).addClass('show');
		}
	});

	/* - - - - - - - - - - - - - -       rebuild banner       - - - - - - - - - - - - - - - */
	function rebuildBanner() {
		const $bannerNavigation = $('.banner-box__navigation').detach();
		if (window.matchMedia('(max-width: 767px)').matches) {
			$('.banner-slider').before($bannerNavigation);
		} else {
			$('.banner-box__body').prepend($bannerNavigation);
		}
	}
	rebuildBanner();
	$(window).on('resize', rebuildBanner);


	/* - - - - - - - - - - - - - -       init banner slider       - - - - - - - - - - - - - - - */
	if ($('.js-banner-slider').length) {
		const splideBannerSlider = new Splide(".js-banner-slider", {
			type: 'loop',
			// drag: 'free',
			perPage: 1,
			perMove: 1,
			arrows: false,
			pagination: false,
			speed: 500,
			easing: 'ease',
			// autoWidth: true,
			// pauseOnHover: false,
			// autoScroll: {
			// 	speed: 1,
			// 	pauseOnHover: false,
			// }
		});
		$('.banner-box__prev-button').on('click', () => {
			splideBannerSlider.go('<');
		});
		$('.banner-box__next-button').on('click', () => {
			splideBannerSlider.go('>');
		});
		$(document).on('click', '.banner-box__pagination-page', function() {
			const slideIndex = parseInt($(this).attr('data-slide'));
			splideBannerSlider.go(slideIndex);
		});
		function changeBannerContent() {
			const activeIndex = splideBannerSlider.index;
			const activeSlide = splideBannerSlider.Components.Slides.getAt(activeIndex); // Отримуємо активний слайд за індексом
			const $activeBannerSlide = activeSlide ? $(activeSlide.slide) : '';
			const $bannerTitle = $activeBannerSlide.find('.banner-hidden__title').html();
			const $bannerDate = $activeBannerSlide.find('.banner-hidden__date').html();
			const $bannerInfo = $activeBannerSlide.find('.banner-hidden__info').html();
			const $bannerButton = $activeBannerSlide.find('.banner-hidden__button').html();
			$('.banner-box__title').html($bannerTitle);
			$('.banner-box__date').html($bannerDate);
			$('.banner-box__info').html($bannerInfo);
			$('.banner-box__button').html($bannerButton);
			$('.banner-box__pagination-page').each(function() {
				$('.banner-box__pagination-page').removeClass('active');
				$(`.banner-box__pagination-page[data-slide="${activeIndex}"]`).addClass('active');
			});
		}
		splideBannerSlider.on( 'mounted', () => {
			// console.log('Splide був змонтований');
			const $bannerPagination = $('.banner-box__pagination');
			const countSlides = splideBannerSlider.length;
			let paginationTpl = '';
			let paginationButtonsTpl = '';
			for (let i = 0; i < countSlides; i++) {
				paginationButtonsTpl += `<li><button class="banner-box__pagination-page" data-slide="${i}">${i+1}</button></li>`;
			}
			if (countSlides) {
				paginationTpl = `<ul>${paginationButtonsTpl}</ul>`;
			}
			$bannerPagination.html(paginationTpl);
			changeBannerContent();
		}).on('move', (newIndex) => {
			// console.log('Активний слайд змінився на індекс: ', newIndex);
			changeBannerContent();
		});
		splideBannerSlider.mount();
	}

	/* - - - - - - - - - - - - - -       init exhibition slider       - - - - - - - - - - - - - - - */
	if ($('.js-exhibition-slider').length) {
		const splideExhibitionSlider = new Splide(".js-exhibition-slider", {
			type: 'false',
			// drag: 'free',
			perPage: 2,
			perMove: 1,
			arrows: true,
			pagination: false,
			speed: 500,
			easing: 'ease',
			// autoWidth: true,
			// pauseOnHover: false,
			// autoScroll: {
			// 	speed: 1,
			// 	pauseOnHover: false,
			// }
		});
		splideExhibitionSlider.mount();
	}

	initEventsGrid();
});
